import React from 'react'
import tw from 'twin.macro'
import { GatsbyImage } from 'gatsby-plugin-image'
import ReadMoreButton from '../ReadMoreButton'

export default function TwoCols(props) {
  const { textLeft, textRight, width, readMoreHide, imageLeft, imageRight } =
    props.content

  return (
    <section
      css={[tw`relative`, width === 'contained' && tw`container md:px-10 px-6`]}
      className={`
        twoCols mm-py-dynamic-sm
        ${readMoreHide && 'readMoreHide'}
        ${width === 'space' && 'mm-mx-dynamic'}
      `}
    >
      <div css={tw`md:flex flex-row`}>
        <div css={tw`flex-1 md:pr-32 pb-16 md:pb-0`}>
          {imageLeft[0]?.localFile?.childImageSharp?.gatsbyImageData && (
            <GatsbyImage
              image={imageLeft[0]?.localFile?.childImageSharp?.gatsbyImageData}
              alt={imageLeft[0]?.title}
            />
          )}
          <div dangerouslySetInnerHTML={{ __html: textLeft }}></div>
        </div>
        <div css={tw`flex-1`}>
          {imageRight[0]?.localFile?.childImageSharp?.gatsbyImageData && (
            <GatsbyImage
              image={imageRight[0]?.localFile?.childImageSharp?.gatsbyImageData}
              alt={imageRight[0]?.title}
            />
          )}
          <div dangerouslySetInnerHTML={{ __html: textRight }}></div>
        </div>
      </div>
      <ReadMoreButton text="Læs mere" />
    </section>
  )
}
